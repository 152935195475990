@import 'vars';

.t-faqs{
    #content{
        padding-top: 6rem;
    }
    .faq-card-wrapper{
        padding-bottom: 4rem;
    }
    .faq-card{
        padding-bottom: 3rem;
        border-bottom: 1px solid #AB7C5040;
        .faq-title{
            color:$brown;
            margin-top: 3rem;
            margin-bottom: 0;
            font-size: clamp(16px, 3vw, 20px);
            cursor: pointer;
            letter-spacing: 2px;
            &::after{
                content:url('../assets/arrow-right.svg');
                transform: rotate(90deg);
                display: inline-block;
                margin: 0 1rem;
                
                transition: transform .3s ease-in-out,;
            }
        }
       
        .faq-body{
            transition: max-height .3s ease-in-out, padding-top .3s ease-in-out;
            overflow: hidden;
            padding-top:3rem;
        }
        &.hide{
            .faq-title::after{
                transform: rotate(270deg);
            }
            .faq-body{
                padding-top:0;
            }
        }
    }
}