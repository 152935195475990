/** site links **/
.site-links{
    display: flex;
    .site-link{
        width: 15px;
        height: 15px;
        margin: 0 5px;
        display: flex;
        align-items: center;
    }
    img{
        object-fit: contain;
        width: 100%;
        height: 100%!important;
    }
}