@import 'vars';

/*============ sub page============*/

.page-title{
    font-size: clamp(20px, 5vw, 47px);
    font-weight: 200;
    color:$light-brown;
}
.page-graphic-wrapper{
    max-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: stretch;
    min-height: 300px;
    .page-title{
        padding-top: 150px;
        position: absolute;
        color:white;
        margin:auto;
        align-self: center;
    }
}
.page-graphic{ 
    display: block;
    width: 100%;
    min-height: 100%;
    position: relative;
    img {
        width: 100%;
        height: 100% !important;
        object-fit: cover;
        
    }
    &::after{
        content:"";
        position: absolute;
        inset:0;
        background-color: #ABAAAA;
        mix-blend-mode: multiply;
    }
}
#content{
    padding:4rem 20px;
}
.pager{
    display: flex;
    justify-content: center;
    .button {
        border:1px solid $light-brown;
        color:$black;
        transition: all .3s ease-in-out;
        margin:1rem .5rem;
        background-color:transparent;
        &.active, &:hover{
            background-color: $light-brown;
            color:white;
        }
    }
}
.more-projects{
    margin-top: 6rem;
    .row{
        justify-content: space-between;
        align-items: center;
        margin-bottom: 1rem;
        a{
            font-size: clamp(16px, 3vw, 20px);
            font-weight: 700;
            color: $brown;
            text-decoration: none;
        }
        .title{
            font-family: $font-secondary;
            font-size: clamp(20px, 5vw, 47px);
            font-weight: 200;
            text-transform: uppercase;
        }
    }
    .projects-items{
        margin:0;
        padding:0;
        margin-bottom: 6rem;
        a{
            text-decoration: none;
            .card{
                font-size: clamp(16px, 3vw, 20px);
                font-weight: 700;
                color: $brown;
                text-transform: uppercase;
                .img-wrapper{
                    aspect-ratio: 5/4;
                    width: 100%;
                    height: auto;
                    position: relative;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    img{
                        height: 100%!important;
                        width: 100%;
                        object-fit: cover;
                    }
                    &::after{
                        content:'';
                        position: absolute;
                        inset:0;
                        background-color: #ABAAAA;
                        mix-blend-mode: multiply;
                        opacity: 0;
                        transition: opacity .3s ease-in-out;
                    }
                    &::before{
                        content:'VIEW PROJECT';
                        position: absolute;
                        color: white;
                        opacity: 0;
                        transition: opacity .3s ease-in-out;
                        z-index: 1;
                        border: 1px solid white;
                        padding:1rem;
                        display: block;
                    }
                }
                &:hover{
                    .img-wrapper{
                        
                        &::after, &::before{
                            opacity: 1;
                        }
                        
                    }
                }
            }
        }
  
      
    }
}

.masonry-layout{
    .item{
       
        padding: 5px;
    }
    .grid-sizer, .item{
        width: 33.33%;
        // width: 100%;
        @media screen and (max-width:768px) {
            width: 50%;
        }
        
    }
    .hidden{
        display: none;
    }
  
}
.vbox-container{
    overflow-y: auto;
}
.vbox-inline{
    display: flex;
    flex-direction: column;
    width: auto;
    img{
        object-fit: contain;
        max-height: 80vh;
    }
    .image-content{
        background-color: #fff;
        padding:20px;
        .title{
            font-weight: 800;
            font-size: 20px;
            color:$brown;
        }
        .description{
            margin-bottom: 20px;
        }
        .share-bar{
            border-top:1px solid $brown;
            padding-top: 20px;
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            gap: 1rem;
            align-items: center;
            a, .share{
                text-decoration: none;
                font-weight: 700;
                color:$brown;
            }
            .share{
                display: flex;
                gap:10px;
                align-items: center;
                justify-content: center;
                svg{
                    height: 20px;
                    width: 20px;
                }
            }
        }
    }
}