/*============ splash ============*/
html{
    scroll-behavior: smooth;
}
/*  banner */
.banners-home{
    display: flex;
    position: relative;
    min-height: 100vh;
    width: 100%;
    justify-content: stretch;
    background-color: #000;
    pointer-events: none;
    .banner-stage {
        display: block;
        position:relative;
        min-height: 100%;
        width: 100%;
        
        .banner {
            position:absolute;
            top:0;
            left:0;
            right:0;
            bottom:0;
            display:flex;
            justify-content: center;
            align-items: center;
            background-position:center;
            background-size:cover;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            color:#fff;
            text-decoration: none;
            &.active{
                opacity: 1;
                z-index: 1; 
            }
     
            .banner-content{
                text-align: center;
                padding:1rem;
                height: 100%;
                width: 100%;
                background-color: #ABAAAA;
                color:#fff;
                display: flex;
                flex-direction: column-reverse;
                justify-content: center;
                align-items: center;
                font-family: $font-secondary;
                .banner-inner-image{
                    mix-blend-mode: multiply;
                    width: 100%;
                    height: 100%!important;
                    object-fit: cover;
                    position: absolute;
                    z-index:1;
                    &::before{
                        content:"";
                        position: absolute;
                        inset:0;
                        mix-blend-mode: multiply;
                        z-index: 5;
                    }
                }
                .banner-caption{
                    z-index: 2;
                    font-size: clamp(24px, 5vw, 47px);
                    font-weight: 200;
                    margin-bottom: 1.5rem;
                    text-transform: uppercase;
                }

                .banner-name{
                    pointer-events: all;
                    z-index: 2;
                    font-size: clamp(16px, 3vw, 20px);
                    transition: border-bottom .3s ease-in-out;
                    border-bottom: 1px solid transparent;
                    &::before{
                        content:"VIEW ";
                        display: inline;
                    }
                    &:hover{
                        border-bottom: 1px solid white;
                    }
                }
             
                .banner-title{
                    display: none;
                }
            }
            
        }
    }
} 
.arrow-down-wrapper{
    position: absolute;
    display: flex;
    justify-content: center;
    width: 100%;
    margin-top: -40px;
    z-index: 1;
}
.banners-home .banner-navigation {
    position: absolute;
    display:block;
    bottom:40px;
    left: 0;
    right: 0;
    z-index: 20;
    pointer-events: all;
    .banner-navigation-body{
        display: flex;
  
        justify-content: center;
        color: $grey;
        counter-reset: my-counter;
        padding:2rem;
        
        .banner-nav-item{
            counter-increment: my-counter;
            font-family: $font-secondary;
            font-size: clamp(16px, 3vw, 20px);
            cursor: pointer;
            transition: color .3s ease-in-out;
            margin:0 2rem;
            -webkit-tap-highlight-color: transparent;
            -webkit-touch-callout: none;
            user-select: none;
            &:focus{
                outline: none !important;
            }
            &:hover{
                color:white;
            }
            &::before {
                content: "0" counter(my-counter);
                display: block;
                margin-bottom: 1rem;
            }
            .banner-name, .banner-caption{
                display:none;
            }
            .banner-title{
                display: block;
                padding-bottom: 2rem;
            }
            &.active{
                color:white;
                .banner-title{
                    border-bottom: 2px solid white;
                }
            }
            @media screen and (max-width:768px) {
                margin:0 .75rem;
                width: 50px;
                min-width: 20px;
                padding-bottom: 2rem;
                border-bottom: 2px solid white;
                .banner-title{
                    display: none;
                }
                
                &::before {
                    display: none;
                }
            }
        }
       
        
        
    }
}