@import 'vars';

/*============ sub page============*/
.t-project{
    .contentwrap{
        max-width: 1200px;
    }
    .content-wrapper{
        .page-graphic{
            width: 100%;
            height: auto;
            object-fit: cover;
            aspect-ratio: 1/1;
        }
        
        .content{
            max-width: 500px;
            margin-left:min(4rem,5vw);
            height: 100%;
            padding:20px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .page-title{
                text-transform: uppercase;
                width: 100%;
                font-weight: 700;
                font-size: clamp(16px, 3vw, 20px);
            }
        }
           
        @media screen and (max-width:960px) {
            grid-template-columns: 1fr;
            .page-graphic{
                aspect-ratio: 16/9;
            }
            .content{
                max-width: none;
            }
        }
      
    }
   
    .filter-wrapper{
        margin-bottom: 3rem;
       
        select, button{
            border:1px solid $light-brown!important;
            height: 52px;
            text-transform: uppercase;
            padding:10px 20px;
        }
        select{
            margin-right: 1rem;
            flex-grow: 3;
            -webkit-appearance: none;
            -moz-appearance: none;
            background-size: 40px;
            background-image: url("data:image/svg+xml;utf8,<svg fill='%23DBB38D' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
            background-repeat: no-repeat;
            background-position-x: 100%;
            background-position-y: 50%;
            border: 1px solid #dfdfdf;
            border-radius: 2px;
            padding: 1rem;
            padding-right: 4rem;
            color: $light-brown;
            outline:$light-brown;
        }
   
        button{
            flex-grow: 1;
            color: $light-brown;
            text-align: left;
            transition: background-color .3s ease-in-out, color .3s ease-in-out;
            &:hover{
                background-color: $light-brown;
                color: white;
            }
        }
        @media screen and (max-width:768px) {
            display:grid;
            grid-template-columns: repeat(2, 1fr);
            grid-gap:1rem;
            margin-bottom: 0;
            select{
                margin-right: 0;
            }
            button{
                grid-column: 1 / -1;
            }
        }
        @media screen and (max-width:576px) {
            grid-template-columns: 1fr;
        }
    }
    .page-subtitle{
        font-size: clamp(20px, 5vw, 47px);
        font-family: $font-secondary;
        font-weight: 200;
        margin-top: 6rem;
    }
    .card-container{
        .three-col{
            margin-bottom: 10rem;
        }
    }
   
    .card{
        display: block;
        
        color: $light-brown;
        font-size: clamp(16px, 4vw, 20px);
        font-weight: 700;
        text-decoration: none;
        text-transform: uppercase;
        padding-top: 1rem;
        border-top: 1px solid transparent;
        border-bottom: 1px solid transparent;
        transition: border-color .3s ease-in-out;
        .card-title{
            font-size: clamp(16px, 3vw, 20px);
        }
        .card-img{
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            
            aspect-ratio: 5/4;
            picture{
                width: 100%; 
                height: 100%;
            }
            img{
                width: 100%; 
                height: 100%!important;
                object-fit: cover;
            }
            &::before{
                content:"VIEW PROJECT";
                color: white;
                font-family: $font-secondary;
                position: absolute;
                padding:10px;
                border:1px solid white;
                opacity: 0;
                transition: opacity .3s ease-in-out;
                z-index: 1;
               font-size: 16px;
            }
            &::after{
                content:"";
                background-color: #ABAAAA90;
                position: absolute;
                inset:0;
                opacity: 0%;
                transition: opacity .3s ease-in-out;
                mix-blend-mode: multiply;
            }
            .default_img{
                img{
                    object-fit: contain;
                    filter: brightness(0) saturate(100%) invert(76%) sepia(53%) saturate(227%) hue-rotate(345deg) brightness(89%) contrast(93%);
                }
           
            }
        }
     
        &:hover{
            .card-img::before, .card-img::after{
                opacity: 1;
            }
            border-color: $light-brown;
        }
    }
 
}

.t-project-item{
    .page-type{
        margin-top: 8rem;
        text-transform: uppercase;
        width: 100%;
        font-weight: 700;
        font-size: clamp(16px, 3vw, 20px);
        color:$brown;
        @media screen and (max-width:768px) {
            margin-top: 4rem;
        }
        @media screen and (max-width:576px) {
            margin-top: 2rem;
        }
    }
    .page-title{
        color:$black;
        font-family: $font-secondary;
        margin-bottom: 0;
    }
    .page-graphic-wrapper{
        width: 100%;
        min-height: unset;
        aspect-ratio: 16/7;
        .page-graphic::after {
            display: none;
        }
    }
    #content{
        padding:2rem 20px;
    }
    #contact{
        padding:8rem 20px;
        input{
            color:$black;
            font-size: clamp(16px, 3vw, 20px);
        }
        .contact-title{
            justify-content: space-between;
            color: $brown;
            margin-bottom: 2rem;
            border-top: 1px solid $brown;
            padding-top: 2rem;
        }
        button[type=submit]{
            float: right;
            color:white;
            background-color: $brown;
            padding: .75rem 2rem;
        }
        .recaptcha-notice{
            float: right;
            clear: both;
        }
        .result-wrapper{
            text-align: center;
            font-size: clamp(16px, 3vw, 20px);
            color: $brown;
            #form-result{
                font-size: clamp(20px, 5vw, 47px);
            }
        }
    }
    .masonry{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5rem;
        margin-bottom: 1rem;
        .image-wrapper{
            display: flex;
            .image-thumbnail{
                height: auto;
                width: 100%;
                aspect-ratio: 5/4;
                object-fit: cover;
                margin:0;
            }
      
            transition: filter .3s ease-in-out;
        }
        .image-wrapper:nth-child(4n){
            grid-column: 1 / -1;
            .image-thumbnail{
                aspect-ratio: 10/4;
            }
        }
        @media screen and (max-width: 960px) {
            grid-template-columns:  repeat(2, 1fr);
            .image-wrapper:nth-child(3n){
                grid-column: 1 / -1;
                .image-thumbnail{
                    aspect-ratio: 10/4;
                }
            }
            .image-wrapper:nth-child(4n){
                grid-column:initial;
                .image-thumbnail{
                    aspect-ratio: 5/4;
                }
            }
        }
        @media screen and (max-width: 576px) {
            grid-template-columns:  1fr;
            grid-gap:1rem;
            .image-thumbnail{
                aspect-ratio: 10/6!important;
            }
        }
    }
}