@import 'vars';

.subtemplate-banner-tab, .subtemplate-banner-tab-v2{
    padding-bottom:4rem;
    .block-title{
        font-size: clamp(16px, 3vw, 20px);
        font-weight: 700;
        margin-bottom: 3rem;
    }
    .banners{
        display:flex;
        flex-direction: column-reverse;
       
        .banner-navigation-body{
            display: flex;
            margin-bottom: 1rem;
            flex-wrap: wrap;
            .banner-nav-item{
                margin-right: 2rem;
                padding-bottom: 2rem;
                margin-bottom: 1rem;
                cursor: pointer;
                .banner-title, .banner-caption{
                    display: none;
                }
                .banner-name{
                    font-size: clamp(16px, 4vw, 20px);
                    font-weight:300;
                    &:hover{
                        font-weight: 700;
                    }
                }
                &.active{
                    .banner-name{
                        font-weight: 700;
                    }
                    border-bottom: 1px solid black;
                }
            }
            @media screen and (max-width:576px) {
                flex-direction: column;
                .banner-nav-item{
                    margin-right: 0;
                    margin-bottom: 0;
                    padding-bottom: 1rem;
                    &.active{
                        border-bottom: none;
                    }

                }
            }
        }
        .banner-stage{
            min-height: 500px;
            position: relative;
            .banner{
                opacity: 0;
                position: absolute;
                transition: opacity .3s ease-in-out;
                text-decoration: none;
                color: $black;
                inset:0;
                pointer-events: none;
                &.active{
                    opacity: 1;
                    position: relative;
                    pointer-events:all;
                }
                .banner-content{
                    display: grid;
                    grid-gap: 1rem 3rem;
                    grid-template-columns: 1fr 1fr;
                    grid-template-rows: 1fr 2fr;
                    grid-template-areas:
                    "image title "
                    "image content";
                   
                    .banner-name{
                        display: none;
                    }
                    .banner-inner-image{
                        grid-area: image;
                        aspect-ratio: 1/1;
                        border-radius: 50%;
                        overflow: hidden;
                        object-fit: cover;
                        width: 100%;         
                        max-width: 504px; 
                        margin:auto;
                    }
                    .banner-title{
                        grid-area: title;
                        font-family: $font-secondary;
                        font-weight: 200;
                        font-size: clamp(20px, 5vw, 47px);
                        display: flex;
                        align-items: flex-end;
                        padding-bottom: 2rem;
                    }
                    .banner-caption{
                        grid-area: content;
                        font-size: 16px;
                        &::after{
                            content:"VIEW";
                            font-size: 20px;
                            display: block;
                            font-weight: 700;
                            margin-top: 3rem;
                            font-family: $font-primary;
                        }
                    }
                }
                &:nth-child(even) .banner-content{
                    grid-template-areas:
                    "title image"
                    "content image";
                }
                
            }
        }
    }
    @media screen and (max-width:768px) {
      
        .banners{
            // .banner-navigation-body{
            //     justify-content: center;
            // }
            .banner-stage{
                
                .banner > .banner-content{
                    justify-items: center;
                    align-items: center;
                    text-align: center;
                    grid-template-areas:
                    "image"
                    "title"
                    "content"!important;
                    grid-template-rows:auto;
                    grid-template-columns: 1fr;
                    .banner-inner-image{
                        padding:2rem;
                    }
                }
                .banner-title{
                    padding-top: 2rem;
                    justify-content: center;
                }       
                
            }
        }
    }
    @media screen and (max-width:576px) {
        text-align: center;
        .banners{
            .banner-navigation-body{
                justify-content: center;
            }
            .banner-stage{
                
                .banner > .banner-content{
                  
                    grid-template-areas:
                    "image"
                    "title"
                    "content"!important;
                    grid-template-rows:auto;
                    grid-template-columns: 1fr;
                }
                .banner-title{
                    padding-top: 2rem;
                    justify-content: center;
                }       
                
            }
        }
       
    }
}
.subtemplate-banner-tab-v2{
    padding:4rem 0;
    .banners {
        margin-top: 4rem;
        .banner-navigation-body{
            margin-bottom: 4rem;
            .banner-nav-item{
                        
                .banner-name{
                    color:$brown;
                    transition: font-weight .3s linear;
                }
                &.active, &:hover{
                    border-bottom: none;
                    .banner-name{
                        font-weight: 700;
                        text-shadow: none;
                    }
                }
            }   
            @media screen and (max-width:768px) {
                margin-bottom: 0;
            }
        }
        .banner-stage .banner{

            .banner-content{
             
                grid-template-areas:
                    "image content"
                    "image title";
                .banner-title{
                    align-items: flex-start;
                }
                .banner-caption{
                    display: flex;
                    align-items: flex-end;
                    color: $brown;
                    font-weight: 700;
                    &::after{
                        display: none;
                    }
                }
            }
            &:nth-child(even) .banner-content{
                grid-template-areas:
                "content image"
                "title image";
            }
            &:hover{
                .banner-caption{
                    text-decoration: underline;
                }
            }
        }
    }
}
.subtemplate-banner-tab-full{
    max-height: 100vh;
    height: 40vw;
    min-height: 300px;
    width: 100%;
    position: relative;
    background-color: #383D47;
    @media screen and (max-width:1200px) {
        height: 50vw;
    }
    @media screen and (max-width:960px) {
        height: 60vw;
    }
    @media screen and (max-width:768px) {
        height: 70vw;
    }
    .block-title {
        position: absolute;
        top:0;
        left:0;
        right:0;
        z-index: 10;
        h2{
            color:white;
            font-size: 20px;
            font-weight: 700;
        }
    }
    .banner-stage{
     
        .banner{
            position: absolute;
            inset:0;
            opacity: 0;
            transition: opacity .3s ease-in-out;
            background-blend-mode: multiply;
            background-color: #ABAAAA;
            background-repeat: no-repeat;
            background-size: cover;
            &.active{
                opacity: 1;
            }
            .banner-content{
                max-width: 1200px;
                margin:0 auto;
                padding:20px;
                padding-bottom: 120px;
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                .banner-name{
                    display: none;
                }
                .banner-title{
                    color:white;
                    font-size: clamp(16px, 3vw, 20px);
                    font-weight: 700;
                    margin-bottom: 5vw;
                }
                .banner-caption{
                    font-size: clamp(20px, 4vw, 47px);
                    font-weight: 200;
                    font-family: $font-secondary;
                    display: block;
                    color:white;
                    max-width: 350px;
                }
            }
            
        }
    }
    
    .banner-navigation{
        bottom:0;
        left:0;
        right:0;
        z-index: 10;
        position: absolute;
        padding-bottom: max(5px, 3vw);
        .banner-navigation-body{
            padding:20px;
            max-width: 1200px;
            margin:0 auto;
            display: flex;
            justify-content: space-between;
            
            .banner-caption, .banner-title{
                display: none;
            }
            .banner-name{
                transition: color .3s ease-in-out;
                color:rgba(255,255,255,.4);
                font-weight: 300;

            }
            .banner-nav-item{
                padding-right: 10px;
                padding-bottom: 2rem;
                cursor: pointer;
                &.active{
                    border-bottom:1px solid white;
                    .banner-name{
                        color:rgba(255,255,255,1);
                        font-weight: 700;
                    }
                }
                @media screen and (max-width:576px) {
                    border-bottom:2px solid rgba(255,255,255,.4);
                    min-width: max(20px, 10vw);
                    user-select: none;
                    -webkit-tap-highlight-color: transparent;
                    -webkit-touch-callout: none;
                    &.active{
                        border-bottom:2px solid white;
                    }
                    &:focus{
                        outline: none !important;
                    }
                    .banner-name{
                        display: none;
                    }
                }
            }

        }
    }
}
.subtemplate-gallery-carousel{
    margin:10px 0;
    .splide__slide {
        &:hover{
            filter: brightness(1.1);
        }
        filter: brightness(.9);
        transition: filter .3s linear;
    }
    .splide__track{
        overflow: visible;
    }
    .image-wrapper{
        display:flex;
        justify-content: stretch;
        align-items: stretch;
        
        // padding:0 5px;
        .image-thumbnail{
            display: block;
            width: 100%;
            aspect-ratio: 5/3;
            background-size: cover;
            background-repeat: no-repeat;
            box-sizing: border-box;
            height: auto;
            margin:0 5px;
        }
    }
 
}
.subtemplate-sponsor{
    padding:4rem 0;
    h2{
        margin-bottom: 4rem;
        font-size: clamp(16px, 3vw, 20px);
    }
    .sponsor-wrapper{
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap:2rem;
        align-items: center;
        justify-items: center;
        
        img{
            margin:auto;
            &:hover{
                transform: scale(1.2);
            }
            transition: transform .3s ease-in-out;
            
        }
        @media screen and (max-width:960px) {
            grid-template-columns: repeat(4, 1fr);
        }
        @media screen and (max-width:576px) {
            grid-template-columns: repeat(3, 1fr);
        }
        @media screen and (max-width:300px) {
            grid-template-columns: repeat(2 , 1fr);
        }
    }
}
.subtemplate-testimonial{
    padding:4rem 0;
    background-color: $brown;
    color:white;
    a{
        text-decoration: none;
    }
    .block-title{
        h2, a{
            font-size: clamp(16px, 3vw, 20px);
            font-weight: 700;
        }
        margin-bottom: 2rem;
        @media screen and (max-width:576px) {
            flex-direction: column;
            h2{
                align-self: flex-start;
            }
            a{
                align-self: flex-end;
                font-weight: 300;
                text-decoration: underline;
            }
        }
    }
    .testimonial-name{
        margin-top: 3rem;
        font-size: 16px;
    }
}
.subtemplate-project{
    color:white;
    text-transform: uppercase;
    background-color: $dark-grey;
    padding:4rem 0;
    a{
        text-decoration: none;
        color:white;
    }
    .splide{
        margin-left:-.5rem;
        margin-right:-.5rem;
        .splide__slide {
            &:hover{
                filter: brightness(1.1);
            }
            filter: brightness(.9);
            transition: filter .3s linear;
        }
    }
    .image-wrapper{
        padding:.5rem;
        img{
            width: 100%;
            height: auto;
            aspect-ratio: 5/4;
        }
        h3{
            font-size: clamp(16px, 3vw, 20px);
        }
    }
    .block-title{
        align-items: flex-end;
        margin-bottom: 3rem;
        h2{
            font-family: $font-secondary;
            font-size: clamp(20px, 5vw, 47px);
            font-weight:200;
            margin-top:0;
            line-height: 1;
        }
        a{
            font-size: clamp(16px, 3vw, 20px);
            font-weight:700;
        }
        @media screen and (max-width:576px) {
            flex-direction: column;
            h2{
                align-self: flex-start;
            }
            a{
                align-self: flex-end;
                font-weight: 300;
                text-decoration: underline;
            }
        }
    }
}
.subtemplate-content-image{
    margin-top: 5rem;
    position: relative;
    background-color: $brown;
    color: white;
    .block-icon{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        padding:0;
        
        img{
            justify-items: center;
            align-items: center;
            position: absolute;
            right:20px;
            aspect-ratio: 1/1;
            border-radius: 50%;
            width: 100%;
            max-width: 300px;
            object-fit: cover;
            transform: translateY(-50%);
            @media screen and (max-width:960px) {
                width: 33vw;
            }
            @media screen and (max-width:500px) {
                width: 40vw;
                right:0;
                left:0;
                margin:auto;
            }
            @media screen and (max-width:300px) {
                width: 60vw;
                right:0;
                left:0;
                margin:auto;
            }
        }
    }
    .block-content{
        padding: 6rem 20px;
        .block-title{
            margin-bottom: 3rem;
            font-size: clamp(16px, 3vw, 20px);
        }
    }
}
.subtemplate-gallery-masonry{
    .block-title{
        font-size: clamp(16px, 3vw, 20px);
        color:$brown;
        font-weight: 700;
        margin-bottom: 4rem;
    }
    .masonry{
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap: 1.5rem;
        margin-bottom: 1rem;
        .image-wrapper{
            display: flex;
            .image-thumbnail{
                height: auto;
                width: 100%;
                aspect-ratio: 5/4;
                object-fit: cover;
                margin:0;
                display: flex;
                justify-content: center;
                align-items: center;
                background-blend-mode: multiply;
                transition: background .3s ease-in-out;
            }
            &:hover{
            
                .image-thumbnail{
                    background-color: #999;
                    &::after{
                        content:'VIEW';
                        position: absolute;
                        font-size: 20px;
                        display: block;
                        font-weight: 500;
                        margin-top: 3rem;
                        font-family: $font-primary;
                        padding:1rem 2rem;
                        color: white;
                        border:1px solid white;
                    }
                }
                
            }
          
            transition: filter .3s ease-in-out;
        }
        .image-wrapper:nth-child(4n){
            grid-column: 1 / -1;
            .image-thumbnail{
                aspect-ratio: 10/4;
            }
        }
        @media screen and (max-width: 960px) {
            grid-template-columns:  repeat(2, 1fr);
            .image-wrapper:nth-child(3n){
                grid-column: 1 / -1;
                .image-thumbnail{
                    aspect-ratio: 10/4;
                }
            }
            .image-wrapper:nth-child(4n){
                grid-column:initial;
                .image-thumbnail{
                    aspect-ratio: 5/4;
                }
            }
        }
        @media screen and (max-width: 576px) {
            grid-template-columns:  1fr;
            grid-gap:1rem;
            .image-thumbnail{
                aspect-ratio: 10/6!important;
            }
        }
    }
}