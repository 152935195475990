
/*============ Contact ============*/
.t-contact{
    main{
        a{
            text-decoration: none;
            color:inherit;
        }
        textarea{
            padding-top:10px;
            min-height: 165px;
        }
        input, textarea{
            color:$black;
            font-size: clamp(16px, 3vw, 20px);
        }
        .contact-title{
            justify-content: space-between;
            color: $brown;
            margin-bottom: 2rem;
            border-top: 1px solid $brown;
            padding-top: 2rem;
        }
        button[type=submit]{
            float: left;
            color:white;
            background-color: $brown;
            padding: .75rem 2rem;
        }
        .recaptcha-notice{
            float: right;
            clear: both;
        }
        .result-wrapper{
            height: 100%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            text-align: center;
            font-size: clamp(16px, 3vw, 20px);
            color: $brown;
           
            #form-result{
                margin-top: 0;
                font-size: clamp(20px, 5vw, 47px);
                
            }
        }
    }
  
    .contact-details p {
        margin: 5px 0;
    }
    .opening-hours {
        display: block;
    }
    .contact-wrapper form {
        padding-top: 20px;
    }
    .contact-wrapper form p {
        margin: 10px 0;
    }
    
    .map-wrap {
        position: relative;
        background-color: #d3d3d3;
        padding-top: 280px;
    }
    .kms-loader {
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -50px;
    }
    #map,
    #map-click {
        display: none;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
    #map {
        display: block;
    }
    .contact-details{
        padding:6rem 0;
    }
    .section{
        margin-bottom: 4rem;
    }
    .section-title{
        font-weight: 700;
        font-size: clamp(16px, 3vw, 20px);
        color: $brown;
    }
    .section-body{
        font-size: clamp(20px, 4vw, 35px);
        font-weight: 200;
        font-family: $font-secondary;
    }
    .contact-address{
        font-family: $font-secondary;
        font-size: clamp(20px, 5vw, 47px);
        font-weight: 200;
        padding-bottom: 2rem;
    }
    .contact-body{
        line-height: 28px;
    }
    .section{
        .site-links {
            margin-top:2rem;
            .site-link{
               
                margin-right: 10px;
                height: 24px;
                width: auto;
                transition: all .3s ease-in-out;
                img{
                    object-position: left;
                    width: auto;
                    @media screen and (max-width:768px) {
                        object-position: center;
                    }
                }
                &:first-child{
                    margin-left:0;
                }
                &:hover{
                    filter: brightness(0) saturate(100%) invert(54%) sepia(8%) saturate(2234%) hue-rotate(348deg) brightness(94%) contrast(87%);
                }
                @media screen and (max-width:768px) {
                    img{
                        object-position: center;
                    }
                    &:first-child{
                        margin-left:10px;
                    }
                    margin-left:10px;
                }
            }
        }
    }
    
    
   
    hr{
        border-color: #AB7C5040;
    }
    .first-wrapper{
        margin-bottom: 6rem;
    }
    .second-wrapper{
        padding: 2rem 0;
        border-top: 1px solid #AB7C5040;
        border-bottom: 1px solid #AB7C5040;
        margin-bottom: 6rem;
    }

    
    @media screen and (max-width: 768px){
        text-align: center;
        .site-links{
            justify-content: center;
        }
        .contact-details{
            padding:3rem 0;
        }
        .first-wrapper{
            margin-bottom: 0;   
        }
    }
    @media screen and (max-width: 578px){
        .contact-details{
            padding:0;
        }
        .map-wrapper{
            padding:0;
        }
        .section-address{
            padding:20px;
        }
        button[type=submit]{
            float: none;
            width: 100%;
        }
        .second-wrapper{
            margin-bottom: 0rem;
        }
    }
}
