@import 'vars';

/*============ header ============*/
header.top {
    font-family: $font-primary;
    font-size:14px;
    position: absolute;
    top:0;
    left:0;
    right: 0;
    z-index: 10;
    color:#ffffff;
    letter-spacing: 1;
}


header.top ul {

}
header.top li {
    vertical-align:top;
}
.header-menu {
    height: 45px;
    display: flex;
    align-items: stretch;
    justify-content: flex-end;
    border-bottom: 1px solid #BEBEBE5F; 
    color:#BEBEBE;
    .header-contact{
        font-family: $font-secondary;
        font-weight: 300;
        font-size: 12px;
        line-height:16px;
        display: flex;
        align-items: center;
        border-right:1px solid #BEBEBE5F;
        padding-right: 1rem;
        a{
            margin-left: 1rem;
            transition: color .3s ease-in-out;
            &:hover{
                color:white;
            }
        }
        @media screen and (max-width:576px) {
            a{
                display: none;
            }
        }
    }
    .header-site-links{
        display: flex;
        align-items: center;
        padding-right: 3rem;
        .social-link-icon{
            filter: brightness(.7);
            transition: filter .3s ease-in-out;
            &:hover{
                filter: brightness(1);
            }
        }
    }
}

.header-bar-bottom {
    position:relative;
    .main-menu{
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 3rem;
        padding-right: 3.5rem;
        a.logo{
            margin-right: 2rem;
        }
        @media screen and (max-width: 960px){
            padding:0 1rem;
        }
    }
}

.mobile-menu-wrapper > ul {
    font-size: 16px;
    text-align:justify;
  
    font-family: $font-secondary;
    font-weight: 500;
 
    display: flex;
    align-items: center;
    a[href='']{
        pointer-events: none;
    }
    .depth-0{
        margin-right: 2vw;
        a{
            transition: color .3s ease-in-out;
            &:hover{
                color:$light-brown;
            }
        }
        
        
    }
    .depth-0.leaf{
        &:hover{
            & > a{
                position: relative;
                &::after{
                    content: "";
                    left: 0;
                    top: 130%;
                    right: 0;
                    position: absolute;
                    border-bottom: 1px solid $light-brown;
                }
            }
        }
    }
    .depth-0.has-sub{
        // position: relative;
        & > ul{
            overflow: hidden;
            display: flex;
            flex-direction: column;
            max-height: 0%;
            width: fit-content;
            
            position: absolute;
            align-items: flex-start;
            transition: max-height .5s ease-in-out;
        }
        &:hover{
            & > ul{
                max-height: 600px;
            }
        }
    }
    
    @media screen and (max-width: 960px){
        & > li:nth-last-child(n+2){
            display: none;
        }
    }
    @media screen and (max-width: 768px){
        & > li{
            display: none;
        }
    }
   
}
.t-heading-white{
    header.top{
        position: relative;
        color:$black;
        a.logo{
            img{
                filter: brightness(0) saturate(100%) invert(76%) sepia(53%) saturate(227%) hue-rotate(345deg) brightness(89%) contrast(93%);
            }
        }
        .depth-0.has-sub > ul{
            background-color: white;
            padding-left: 10px;
            padding-right: 10px;
            margin-left: -10px;
  
        }
        border-bottom: 1px solid #ab7c5045;
    } 
}


