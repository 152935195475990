/*============ footer ============*/
footer .footer-bottom {
    background-color:#262930;
    color:#fff;
    .footer-subscribe{
        .subscribe-form{
            display: none;
        }
    }
    .three-col{
        grid-gap:2rem;
    }
    .footer-heading{
        padding:4rem;
        border-bottom:1px solid #4D525E;
        @media screen and (max-width:960px){
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            .depth-0{
                margin-bottom: .5rem;
            }
        }
        a.logo {
            img{
                filter: brightness(0) saturate(100%) invert(76%) sepia(53%) saturate(227%) hue-rotate(345deg) brightness(89%) contrast(93%);
            }
        }
        .footer-menu{
            grid-column: 2/-1;
            display: flex;
            align-items: center;
            font-family: $font-secondary;
            font-weight: 500;
            position: relative;
            flex-wrap: wrap;
            ul{
                display: flex;
                align-items: center;
                flex-wrap: wrap;
                @media screen and (max-width:960px){
                    flex-direction: column;
                }
                a[href='']{
                    pointer-events: none;
                }
                .depth-0{
                    margin-right: 3vw;
                    text-align: center;
                    white-space: nowrap;
                    a{
                        transition: color .3s ease-in-out;
                        &:hover{
                            color:$light-brown;
                        }
                    }
                    @media screen and (max-width:1400px) {
                        margin-right: 2vw;
                    }
                    
                }
                .depth-0.leaf{
                    &:hover{
                        & > a{
                            position: relative;
                            &::after{
                                content: "";
                                left: 0;
                                top: 130%;
                                right: 0;
                                position: absolute;
                                border-bottom: 1px solid $light-brown;
                            }
                        }
                    }
                }
                .depth-0.has-sub{
                    // position: relative;
                    & > ul{
                        display: flex;
                        flex-direction: column;
                        max-height: 0%;
                        overflow: hidden;
                        position: absolute;
                        align-items: flex-start;
                        transition: max-height .3s ease-in-out;
                    }
                    &:hover{
                        & > ul{
                            max-height: 150%;
                            
                        }
                    }
                }
            }
        }
    }
    .footer-content{
        padding:4rem 5rem;
        text-transform: uppercase;
        font-weight: 200;
        font-family: $font-secondary;
        font-size: clamp(16px, 3vw, 20px);
        @media screen and (max-width:576px) {
            padding:4rem ;
        }
        .col{
            margin-bottom: 3.5rem;
            
            p{  
                margin-top: 0;
                margin-bottom: 1rem;
                &.title{
             
                    font-family: $font-primary;
                    font-size: 16px;
                    font-weight: bold;
                    margin-bottom: 1.5rem;
                }
            }
            @media screen and (max-width:576px) {
                text-align: center;
            }
        }
        .footer-block{
            text-transform: none;
            max-width: 400px;
        }
        .site-links {
            justify-content: flex-start;
            .site-link{
                height: 28px;
                width: 28px;
                margin-right: 1rem;
                margin-left:0;
                transition: filter .3s ease-in-out;
                &:hover{
                    filter: brightness(0) invert(1);
                }
                img{
                    object-position: left;
                }
            }
            @media screen and (max-width:768px) {
                justify-content: center;
                .site-link{
                    margin-left:.5rem;
                    margin-right:.5rem;
                    img{
                        object-position: center;
                    }
                }
            }
        }
        .corner-logo{
            margin:0;
            display: flex;
            img{
                margin-left: auto;
            }
            @media screen and (max-width:576px) {
                img{
                    margin: auto;
                }
            }
        }
    }
}

/** bykeo ***/
.bykeo {
    display: block;
    text-align: center;
    font-size: 12px;
    padding-top: 10px;
    padding-bottom: 20px;
    width: 100%;
    box-sizing: border-box;
}
.bykeo a {
    color: white;
    text-decoration:none;
}
.bykeo img {
    vertical-align: middle;
    display: inline-block;
    transition: transform 500ms ease;
}
.bykeo a:hover img{
    transform-origin: center;
    transform: translateX(-200%) rotate(-360deg);
}

.before-footer > .gallery-autoscroll{
    .splide__slide {
        &:hover{
            filter: brightness(1.1);
        }
        filter: brightness(.9);
        transition: filter .3s linear;
    }
    .splide__track{
        overflow: visible;
    }
    .image-wrapper{
        width: 100%;
        aspect-ratio: 5/4;
        padding:10px 5px;
        margin:0;
        .image-thumbnail{
            
            margin:0;
            padding:2rem;
            background-repeat: no-repeat;
            background-size: cover;
            width: 100%;
            height: 100%;
            background-color: #ABAAAA;
            background-blend-mode: multiply;
            color:white;
            text-transform: uppercase;
            text-decoration: none;
            font-family: $font-secondary;
            font-size: clamp(16px, 3vw, 20px);
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            align-items: flex-start;
            p{
                margin-top:0;
                margin-bottom: 1rem;
                font-family: $font-secondary;
                font-size: clamp(16px, 3vw, 20px);
            }
            @media screen and (max-width:768px) {
                padding:1rem;
            }
        }
    }
 
}