@import 'vars';

/*============ sub page============*/
.t-service{
    .after-content{
        align-items: center;
        margin-top: 6rem;
        .subtitle{
            font-family: $font-secondary;
            font-size: clamp(20px, 4vw, 47px);
            font-weight: 200;
        }
        .summary{
            font-size: 16px;
            max-width: 400px;
            margin-bottom: 4rem;
        }
        .icon-wrapper{
            margin:2rem;
            img{
                border-radius: 50%;
                
            }
        }
        .project-link{
            font-weight: 700;
            font-size: clamp(16px, 3vw, 20px);
            text-decoration: none;
            color:inherit;
        }
    }
  
}