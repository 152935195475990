@import 'vars';

/** form generic **/
form {
    width: 100%;
    box-sizing: border-box;
}
form p {
    position: relative;
    margin: 5px 0;
}
label.float{
    font-family: $font-primary;
}
label.float,
input {
    height: 40px;
    line-height: 40px;
}
label.error {
    display: block;
    font-size: 12px;
    line-height: 1;
}
input,
textarea {
    text-align: left;
    padding-left: 20px;
    font-family: $font-primary;
    font-size: 16px;

    width: 100%;
    box-sizing: border-box;
    background-color: transparent;
    border:1px solid $brown;
    color:white;
    margin-bottom: .75rem;
    height: 52px;
    vertical-align: middle;
    &::placeholder{
        color:white;
    }
}
textarea {
    min-height: 80px;
}
label.float {
    position: absolute;
    left:0;
    text-transform: uppercase;
    color: inherit;
    padding-left: 20px;
    max-width: 100%;
    box-sizing: border-box;
    transition: all 1s ease;
    z-index: 1;
    line-height: 52px;
    font-size:1em;
}
.js-float-wrap {
    position:relative;
    font-size:16px;
    
}
.js-float-wrap.focused label.float,
.js-float-wrap.populated label.float {
    left: 5px;
    padding-left: 0;
    line-height: 16px;
    font-size: 0.75em;
}
form button {
    text-transform: uppercase;
    width: 100%;
    cursor: pointer;
}
button[type=submit]{
    font-family: $font-primary;
    font-size: 16px;
    color:white;
    background-color: transparent;
    width: auto;
    border:none;
    padding:1rem 0;
    padding-right: 1rem;
    margin-bottom: 1rem;
    transition: all .3s linear;
    &:hover{
        
        border-bottom:1px solid $brown;
    }
}