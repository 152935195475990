@import 'vars';

.row{
    display: flex;
}
.justify-between{
    justify-content: space-between;
}
.align-center{
    align-items: center;
}

.mfp-bg{
    background: #262930;
    mix-blend-mode: multiply;
    opacity: 1;
}
.mfp-container{
    display: flex;
    justify-content: center;
    align-items: flex-start;
}
.mfp-content{
    max-width: 80%!important;
    .mfp-bottom-bar{
        background: white;
        padding: 1rem;
        margin-bottom: 2rem;
        transform: translateY(-5px);
        .mfp-title{
            color:$black;
            .title{
                color:$brown;
                font-size: clamp(16px, 3vw, 20px);
                font-weight: 700;
                margin-top: 2rem;
                margin-bottom: 2rem;
            }
            .caption{
                font-size: 16px;
            }
        }
    }
    .pin-it{
        // content:url("../assets/pin-it.svg");
        // display: block;
        color:$brown;
        font-size: clamp(16px, 3vw, 20px);
        padding:2rem 0;
        text-decoration: none;
        border-bottom: 1px solid $brown;
        display: block;
        &:hover{
            font-weight: 700;
        }
    }
    .lets-talk{
        color:$brown; 
        font-size: clamp(16px, 3vw, 20px);
        font-weight: 700;
        cursor: pointer;
        
    }
}
.lets-talk{
    &::after{
        content:url("../assets/arrow-right.svg");
        display: inline-block;
        margin-left:10px;
        transform: translateY(2px);
    }
}
